@font-face {
    font-family: "Space Grotesk";
    src: url("../static/fonts/SpaceGrotesk-Regular.ttf");
    font-weight: 400;
}

@font-face {
    font-family: "Space Grotesk";
    src: url("../static/fonts/SpaceGrotesk-Medium.ttf");
    font-weight: 500;
}

@font-face {
    font-family: "Space Grotesk";
    src: url("../static/fonts/SpaceGrotesk-SemiBold.ttf");
    font-weight: 600;
}

@font-face {
    font-family: "Space Mono";
    src: url("../static/fonts/SpaceMono-Regular.ttf");
}

@font-face {
    font-family: "Space Mono";
    src: url("../static/fonts/SpaceMono-Bold.ttf");
    font-weight: 700;
}

::-webkit-scrollbar {
    width: 0;
    background: transparent;
}

::-moz-selection {
    /* Code for Firefox */
    color: rgba(50, 4, 89, 1);
    background: #DC3842;
}

::selection {
    color: rgba(50, 4, 89, 1);
    background: #DC3842;
}


body {
    margin: 0;
    padding: 0;
    opacity: 0;
    min-height: 100vh;
    min-width: 100vw;
    background-repeat: no-repeat;
    background-color: #000;
    background-image: linear-gradient(to bottom right, rgb(23, 20, 26) 21.2%, rgba(50, 4, 89, 1) 390%);
    font-family: "Space Grotesk";
}

.hidden {
    opacity: 0;
    overflow: hidden;
    background-image: linear-gradient(to bottom right, rgb(23, 20, 26) 21.2%, rgba(50, 4, 89, 1) 130%);
}


.visible {
    opacity: 1;
    transition: opacity 1s ease-out;
    background-image: linear-gradient(to bottom right, rgb(23, 20, 26) 21.2%, rgba(50, 4, 89, 1) 130%);
}

.hero {
    margin: 0;
    height: 100vh;
    width: 100%;
    position: absolute;
    top: 0;
    /* background-image: url(images/sunfall.jpg); */
    /* background-color: rgb(20, 20, 20); */
    background-size: cover;
    background-position: center;
}

.hero::-webkit-scrollbar {
    display: none;
}

.content {
    position: absolute;
    top: 50%;
    left: 8%;
    /* transform: translate(0%,40%); */
    transform: translateY(-50%);
}

.signature {
    background-image: url('../static/images/signature-white.png');
    background-size: contain;
    background-position: left;
    /* background-color: #000; */
    width: 15.2%;
    height: 15.2%;
    background-repeat: no-repeat;
    position: absolute;
    /* background-color: aquamarine; */
    top: 1%;
    left: 4%;
    transform: translateY(-1%);

}

.signature:hover {
    cursor: pointer;
}

.signature:active {
    cursor: pointer;
}

/* 
#loader {
    border: 8px solid #dddddd;
    border-radius: 50%;
  
    border-top: 8px solid #11111100;
    width: 70px;
    height: 70px;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    100% {
        transform: rotate(360deg);
    }
} */

.center {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
}

h1 {
    color: white;
    font-size: 90px;
    /* font-family: "Space Grotesk"; */
    font-family: "Space Grotesk";
    letter-spacing: 10px;
    font-weight: 500;
}

.space-out {
    width: 88vw;
}

h3 {
    color: white;
    font-family: "Raleway";
    transition: letter-spacing 4s;
    font-size: 22px;
    font-weight: 600;
    letter-spacing: 0.3em;
    animation: airplane 2.5s ease-in reverse;
    max-lines: 1;
}

h3:hover {
    letter-spacing: calc(100vw / 26);
}

@keyframes airplane {
    0% {
        letter-spacing: 0.3em;
    }

    100% {
        letter-spacing: calc(100vw / 26);
    }
}

h6 {
    color: white;
    margin-top: 0;
    margin-bottom: 0;
    font-family: "Space Mono";
    font-weight: 700;
    font-size: 17px;
}

p {
    color: white;
    font-size: 19px;
    font-family: "Space Grotesk";
    letter-spacing: 0.5px;
    font-weight: 300;
}

.circles {
    width: 100%;
    text-align: center;
    position: absolute;
}

.fail {
    width: 100%;
    text-align: center;
    opacity: 0;
    position: absolute;
}


.circles,
.fail {
    transition: opacity 0.2s ease-in;
}

.wrap:hover .circles {
    opacity: 0;
}

.wrap:hover .fail {
    opacity: 1;
}

p.multi {
    color: white;
    font-size: 18px;
    text-align: center;
}

strong {
    color: white;
    font-size: 18px;
    font-weight: 600;
}

.gradient {
    width: 100vw;
    padding: 120px 0px 120px 0px;
    /* background-image: linear-gradient(to bottom right, rgb(17, 17, 17), rgb(7, 7, 7)); */
}

.glassmorphism {
    /* position: absolute; */
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    padding: 80px 0px 80px 0px;
    width: 90%;
    /* backdrop-filter: blur(1px) saturate(100%);
    -webkit-backdrop-filter: blur(2px) saturate(100%);
    background-color: rgba(255, 255, 255, 0.01);
    border-radius: 12px;
    border: 1px solid rgba(255, 255, 255, 0.125);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.3); */
}

.overflowglass {
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: 0px;
    overflow: hidden;
    backdrop-filter: blur(1px) saturate(100%);
    -webkit-backdrop-filter: blur(2px) saturate(100%);
    background-color: rgba(255, 255, 255, 0.01);
    border-bottom: 1px solid rgba(255, 255, 255, 0.125);
    border-top: 2px solid rgba(255, 255, 255, 0.08);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.3);
    text-align: center;
}

.overflowglass h4 {
    margin: 15px 0px 15px 0px;
    color: white;
    font-size: 30px;
    font-weight: 500;
    max-lines: 1;
    text-overflow: clip;
    overflow: hidden;
    white-space: nowrap;
    /* letter-spacing: 2px; */
}

.content2 {
    margin-top: auto;
    margin-bottom: auto;
    text-align: center;
    justify-content: center;
    align-items: center;
}

.scrolls {
    width: 100vw;
    /* padding-bottom: 30px; */
    overflow-x: hidden;
    overflow-y: visible;
    justify-items: center;
    justify-content: center;
    margin: 0px;
    /* height: auto; */
    white-space: nowrap;
}

.scrolling-column {
    /* background-color: #DC3842; */
    padding: 40px 0px 30px 0px;
    display: flex;
    overflow: scroll;
    width: 100vw;
    justify-content: center;
    justify-items: center;
    /* pointer-events: all; */
    position: absolute;
    gap: 60px;
    left: 0px;
}

.card {
    min-width: 100px;
    min-height: 100px;
    width: 100px;
    height: 100px;
    padding: 15px;
    justify-content: center;
    border-radius: 50%;
    background: rgb(14, 14, 14);
    backdrop-filter: blur(4px);
    box-shadow: 0 0 9px #000;
    border-top: 3px solid rgba(255, 255, 255, 0.2);
    border-left: 2px solid rgba(255, 255, 255, 0.25);
    -webkit-user-drag: none;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
}

.myself {
    width: 260px;
    height: 260px;
    background-size: cover;
    background-position: center;
    /* background-image: url("../static/images/stuz0r.jpg"); */
    filter: drop-shadow(0px 4px 9px rgba(0, 0, 0, 0.4));
    border-radius: 8px;
}

.card img {
    top: 50%;
    left: 50%;
    position: absolute;
    width: 100px;
    transform: translate(-50%, -50%);
}

/* .card button {
    display: inline-block;
    width: 50px;
    height: 50px;
    font-size: 22px;
    border-radius: 50%;
    border: none;
    margin-right: 10px;
    color: #fff;
    transition: 0.4s all;
    cursor: pointer;
} */

.third {
    width: 100%;
    /* background-image: radial-gradient(100% 316.05% at 0% 0%, #082f4b 0%, #000305 100%); */
    background-size: cover;
    background-position: center;
}

.content3 {
    padding: 35vh 0px 0px 8vw;
    /* position: absolute;
    top: 100%;
    width: 100%;
    text-align: center; */
}

.content3 b {
    font-weight: 500;
}

.content4 {
    margin: 0;
    position: relative;
    width: 100%;
    text-align: center;
}


.column {
    padding: 70px 0px 0px 0px;
    display: flex;
    justify-content: center;
}

.content-column {
    display: flex;
    margin: 0px;
}

.blinking {
    -webkit-animation: 1s blink ease infinite;
    -moz-animation: 1s blink ease infinite;
    -ms-animation: 1s blink ease infinite;
    -o-animation: 1s blink ease infinite;
    animation: 1s blink ease infinite;
}

@keyframes blink {

    from,
    to {
        opacity: 0;
    }

    50% {
        opacity: 1;
    }
}

@-moz-keyframes blink {

    from,
    to {
        opacity: 0;
    }

    50% {
        opacity: 1;
    }
}

@-webkit-keyframes blink {

    from,
    to {
        opacity: 0;
    }

    50% {
        opacity: 1;
    }
}

@-ms-keyframes blink {

    from,
    to {
        opacity: 0;
    }

    50% {
        opacity: 1;
    }
}

@-o-keyframes blink {

    from,
    to {
        opacity: 0;
    }

    50% {
        opacity: 1;
    }
}

@keyframes fadein {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

/* Internet Explorer */
@-ms-keyframes fadein {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@keyframes fadeout {
    from {
        opacity: 1;
    }

    to {
        opacity: 0;
    }
}

/* Firefox < 16 */
@-moz-keyframes fadeout {
    from {
        opacity: 1;
    }

    to {
        opacity: 0;
    }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadeout {
    from {
        opacity: 1;
    }

    to {
        opacity: 0;
    }
}

/* Internet Explorer */
@-ms-keyframes fadeout {
    from {
        opacity: 1;
    }

    to {
        opacity: 0;
    }
}

/* body.loading-fade {
    -webkit-animation: fadeout 5s;
    -moz-animation: fadeout 5s;
    -ms-animation: fadeout 5s;
    -o-animation: fadeout 5s;
    animation: fadeout 5s;
} */